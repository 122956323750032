import React, { useEffect } from "react"
import clsx from "clsx"
import cn from "classnames"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import Img from "./Img"
import ImgFriday from "./ImgFriday"
import ImgHippo from "./ImgHippo"

import * as styles from "./banner.module.scss"
import { useObserver } from "../../../hooks/useObserver"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"
import ImgRostics from "./ImgRostics"

type BannerProps = {
  darkColor?: boolean
  buttonText?: string
  title?: string
  description?: string
  variant?: "pkw" | "notpkw" | "pkwBonus" | "rostics" | "hippo"
  orderNum?: string
  anotherFontSize?: boolean
  bgVariant?: "default" | "rosticsBg"
  variantStyle?: "vkEducation" | "hippo"
}

const defaultTitle = `
  <span>Карта «Халва»</span>
  <br/>
  24 месяца без %
`

const defaultDescription = `
  — До 17% на остаток по карте
  <br />
  — Кэшбэк до 10%
  <br />— Обслуживание 0 ₽
`

export function Banner({
  darkColor,
  buttonText = "Оформить карту",
  title = defaultTitle,
  description = defaultDescription,
  variant = "pkw",
  orderNum,
  anotherFontSize = false,
  variantStyle,
  bgVariant = "default",
}: BannerProps) {
  useEffect(() => {
    document.body.style.display = "block"
  }, [])
  const otherVariantStyle = variantStyle && styles[variantStyle]
  const isVisible = useObserver(["app", "calculatorCard", "reviewCard", "moreBenefits", "formPkw"])

  const renderImage = () => {
    switch (variant) {
      case "notpkw":
        return <ImgFriday alt="bg image" className={styles.imgFriday} />
      case "rostics":
        return <ImgRostics alt="bg image" className={styles.imgRostics} />
      case "hippo":
        return <ImgHippo alt="bg image" className={styles.imgHippo} />
      default:
        return <Img alt="bg image" className={styles.img} />
    }
  }
  return (
    <>
      <section className={clsx(otherVariantStyle)} data-exclude={orderNum}>
        <Container className={cn(styles.container, darkColor && styles.darkColor)}>
          <div
            className={clsx(styles.secondContainer, {
              [styles[bgVariant]]: bgVariant,
              [styles.notpkwBanner]: variant === "notpkw",
            })}
          >
            {renderImage()}

            <div
              className={clsx(
                styles.text,
                { [styles.anotherFontSize]: anotherFontSize },
                styles[variant]
              )}
            >
              <h1 dangerouslySetInnerHTML={{ __html: title }} />
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
              {buttonText}
            </Button>
          </div>
        </Container>
      </section>
      <div className={clsx(styles.btnBlock, { [styles.hideMobBlock]: isVisible })}>
        <Container>
          <Button onClick={() => handleClickBanner("sticky")} className={styles.mobBtn}>
            {buttonText}
          </Button>
        </Container>
      </div>
    </>
  )
}
